import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Vybrané významné pojmy lesnictví" />
    <h1>Vybrané významné pojmy lesnictví</h1>
    <p>Mnoho <strong>pojmů z oblasti lesnictví</strong> a správy lesa bylo popsáno v minulých kapitolách, zde jsou vybrány další, významné pojmy a jejich definice.</p>
    <hr />
    <h2>Těžba obnovní a výchovná</h2>
    <p>„Klasickou“ těžbou rozumíme <strong>těžbu obnovní (tj. mýtní)</strong>, která je za normálních okolností <strong>plánovaná</strong>. Často to ale vlivem nepředpokládaných událostí nefunguje a přichází neplánovaná těžba – tzv. <strong>nahodilá těžba</strong>. Těmito událostmi jsou například napadení stromu chorobou, následky vichřice či v&nbsp;dnešní době nejčastěji – napadení kůrovcem. V&nbsp;případě velkého měřítka nahodilé těžby mluvíme o těžbě kalamitní.</p>
    <p>V&nbsp;současnosti až 60 % těžby u nás je nahodilých a vznikají tak rozsáhlé holoseče. Stromy se kácí i preventivně, aby bylo zachráněno co nejvíce dřevní hmoty. Pokud totiž strom uschne nastojato, ztrácí na své hodnotě, což je zlé pro hospodáře daného lesa.</p>
    <p>Před mýtní těžbou ale dochází ještě k&nbsp;<strong>těžbě výchovné (tj. předmýtní)</strong>, která má za cíl odstranit nemocné či například málo perspektivní stromy. U menších stromů (mlazina, tyčkovina, tyčovina) dochází k&nbsp;<strong>prořezávkám</strong>, u větších (kmenovina, přestárlá kmenovina) k&nbsp;<strong>probírkám</strong>.</p>
    <hr />
    <h2>Obmýtí</h2>
    <p>Obmýtí je doba spočtená dlouhodobým lesnickým výzkumem, kdy je nejekonomičtější daný porost skácen. Je vypočtena pro daný druh stromu a typ stanoviště, aby bylo dosaženo co největšího zisku. Počítá se i s&nbsp;mírou rizika, jako je vývrat, napadení chorobou atd.</p>
    <p>Nejkratší obmýtí mají <strong>topoly a vrby</strong>, zhruba 40 let (některé kultivary jen 20 let). Obmýtí je takto krátké, protože strom má v&nbsp;mládí větší přírůstek, ale poté začne vyhnívat a láme se.</p>
    <p><strong>Bříza</strong> má za podmínek, kdy dostává málo světla, obvykle obmýtí 40 let. Pokud je na stanovišti, kde ho dostává více, obmýtí pak dosahuje 50 až 60 let.</p>
    <p><strong>Olše </strong>mají obmýtí zhruba 70 let, <strong>borovice </strong>dle stanoviště většinou 100 až 120 let.</p>
    <p><strong>Smrk</strong> má v&nbsp;suchých oblastech obmýtí krátké a činí asi 80 let, naopak na horách může dosahovat až 160 let. V&nbsp;průměru je ale podobné jako u borovice.</p>
    <p>Nejdelší obmýtí pak mají v&nbsp;průměru <strong>buky</strong> (100 až 140 let) a vůbec nejvíce <strong>duby</strong> (asi 140 let).</p>
    <p>Dobu obmýtí silně u všech druhů ovlivňuje typ stanoviště, například u smrku či borovice živné stanoviště sice znamená rychlejší růst, rovněž je ale dřevo řidší a strom se pak snadněji láme či je lépe napadnutelný chorobami.</p>
    <p>Porosty nad 170 let se pak automaticky považují za přestárlé. Takové stromy by měly být v&nbsp;hospodářském lese dávno skáceny, protože takový věk je z&nbsp;hospodářského hlediska riskantní pro všechny dřeviny. V&nbsp;některých případech mohou být porosty přerostlé záměrně, například v&nbsp;ochranných lesích na extrémních stanovištích či například v&nbsp;národních přírodních rezervacích.</p>
    <p>I v&nbsp;lesech zvláštního určení, v&nbsp;rezervacích a jinde, se běžně hospodaří. Případné ponechání přestárlých porostů a bezzásahovost musí být vyjednána, takový postup totiž není automatický ani v&nbsp;národních přírodních památkách a rezervacích. Stejně tak musí ochrana přírody dojednat s&nbsp;lesáky případné prodloužení obmýtí či výsadbu jiných druhů dřevin.</p>
    <hr />
    <h2>Obnovní doba</h2>
    <p>Obnovní doba trvá od chvíle, kdy v&nbsp;lese začíná těžba do chvíle, kdy těžba končí. Může mít krátké trvání od několika měsíců či dnů v&nbsp;případě nahodilé těžby (dnešní kůrovcová kalamita), až po desítky let.</p>
    <p>Druhý případ může nastat například u dubového lesa, nejprve začnu s&nbsp;probírkami (tím začíná těžba), dochází k&nbsp;prosvětlení lesa, mineralizuje humus a staré duby mohou ještě ztloustnout. Po deseti letech mohu probírku opakovat, ideálně v&nbsp;semenném roku, aby došlo ke zmlazení porostu. Dále kácím, ale některé stromy nechávám, dokud nebude kultura dubových semenáčků zajištěna. Obnovní doba v&nbsp;takovém případě může trvat až 40 let.</p>
    <p>Prodloužení obmýtí a obnovní doby je z&nbsp;ekologického hlediska vhodné, z&nbsp;hlediska produkce dřeva může být především v&nbsp;případě obmýtí riskantní. Obmýtí a obnovní doba se obvykle prodlužují mimo jiné u <u>genových základen.</u> Z&nbsp;nich je sbíráno osivo do lesních školek ze stromů se žádoucími kvalitami (například ekotypy s&nbsp;rovným a válcovým kmenem). Genové základny jsou obvykle starším, vyzrálejším a ekologicky stabilnějším ekosystémem.</p>
    <hr />
    <h2>Stabilita lesa</h2>
    <p>Rozlišujeme dva základní druhy stability lesa. Pojem <strong>ekologická stabilita lesa</strong> je pojem používaný v&nbsp;geografii a ekologii, znamená, jak je lesní ekosystém resilientní, odolný. Les s&nbsp;vysokou ekologickou stabilitou lépe přežije náhlé nepříznivé podmínky, hůře se v&nbsp;něm například šíří škůdci (dnes hlavně kůrovec).</p>
    <p>Pojem <strong>statická stabilita lesa</strong> je pak používán lesáky. Pokud je les staticky stabilní, tak se nevyvrací a stromy se v&nbsp;něm nelámou. Ideálním stavem je stabilita ekologická i statická.</p>
    <hr />
    <h2>PUPFL</h2>
    <p>PUPFL je zkratka pro <strong>pozemky určené k&nbsp;plnění funkcí lesa</strong>. Jde o velice široký pojem, zahrnuje i polní cesty vedoucí od nejbližší státní silnice k&nbsp;lesu, hájenky, lesní cesty, sklady dřeva či například potoky s&nbsp;koryty. Z&nbsp;98 % je však PUPFL tvořen porostní půdou, na níž rostou stromy.</p>
    <hr />
    <h2>Etát</h2>
    <p>Etát je množství dřeva, které vyroste na ploše za 1 rok. Pokud není kalamita, neměl bych etát přetěžovat, ale ani nedotěžovat. Měl by být ideálně celý vytěžen, aby bylo dosaženo tzv. <u>těžební vyrovnanosti</u>.</p>
    <p>Jednou za 10 let měří taxátoři zpracovávající LHP a LHO přírůstek výběrovou metodou, jestli náhodou porost v&nbsp;lese špatně neroste. Nejprve změří kleštěmi <u>výčetní tloušťku,</u> což je průměr kmene v&nbsp;<u>prsní výšce</u> (1,3 metru nad zemí). Z&nbsp;této pak pomocí empiricky zjištěných růstových tabulek dopočítají objem kmene, jeho výšku a další. S kmenem se zde počítá jako s&nbsp;kuželem se základnou ve výčetní tloušťce, pro každý druh jsou tabulky pochopitelně odlišné.</p>
    <p>Do roku 1850 byl etát silně přetěžovaný, po roce 1948 byl naopak nedotěžován. Tato situace platila až zhruba do roku 2008, kdy se změnilo vedení Lesů ČR. Dnes je etát kvůli kůrovcové kalamitě opět silně přetěžován.</p>
    <hr />
    <h2>Lesní kultura</h2>
    <p>Kultura je mladý porost, který byl založen uměle, síjí či sadbou, s&nbsp;výškou do 1,3 metru. Rozlišujeme nezajištěnou kulturu a zajištěnou kulturu.</p>
    <p><strong>Nezajištěná kultura</strong> je vysoká do 0,6 metru, jde o nejmladší fázi výsadby. Je ohrožená buření, zvěří a dalšími faktory. Musí se tak ošetřovat a ožínat, aby ji buřeň nezahlušila. Na této úrovni se přirozeně vyskytuje <strong>nálet</strong>.</p>
    <p><strong>Zajištěná kultura</strong> je 0,6 až 1,3 metru vysoká, terminální pupen stromku je dostatečně vysoko, aby nebyl tolik ohrožen zahlušením buření a do jisté míry je chráněn i před zvěří. Přirozeně se na této úrovni vyskytuje <strong>nárost.</strong></p>
    <p>Podle průměru kmene, a tudíž dle výsledné velikosti <u>sortimentu,</u> lze pak dále odlišit:</p>
    <ul>
    <li><strong>mlazinu </strong>(výška nad 1,3 m, průměr do 5 cm; z&nbsp;prořezávek vánoční stromečky)</li>
    <li><strong>tyčkovinu </strong>(průměr 6 až 12 cm, věk 20 až 40 let; z&nbsp;prořezávek tyčky do plotů a vinohradů)</li>
    <li><strong>tyčovinu </strong>(průměr 13 až 20 cm; z&nbsp;prořezávek krovy, ohrady pro koně)</li>
    <li><strong>kmenovinu </strong>(průměr nad 20 cm)</li>
    <li><strong>přestárlá kmenovina </strong>(o ½ překročena obnovní doba, starší než asi 110 až 140 let, téměř nulový přírůst, zranitelnost, počátek stádia rozpadu kultury)</li>
    </ul>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Vybrané významné pojmy lesnictví</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/klasifikace-lesu/"><button className="inv">&larr; Klasifikace lesů v ČR</button></Link>
    <Link to="/ekologie-zivotni-prostredi/prichod-a-vymirani-druhu/"><button className="inv">Příchod a vymírání druhů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
